<template>
    <div class="refine-search-fields">
        <label v-if="showLabels" class="field-label">{{ placeholder }}</label>
        <input
            type="text"
            maxlength="17"
            :placeholder="placeholder"
            v-model="currentVin"
            @keydown="handleVinKeydown($event)"
        />
        <driv-refine-search-btn :disabled="isDisabled" :onClick="handleSearch">
        </driv-refine-search-btn>
        <fmmp-error-message v-if="errorMessage" message="invalid vin number" />
        <fmmp-error-message v-if="errorMessageforMX" message="The VIN does not have information yet" />
    </div>
</template>

<script>
import {
    PART_FINDER_CORPORATE_SEARCH_TYPES,
    VIN_LP_VEHICLE_TYPE,
} from "../../../../common/partFinderCorporate.constants";
import {
    createQueryStringForFilters,
    createUrlToGoToSearchResults,
    getTranslation,
    mapVinOrLicensePlateResponse,
} from "../../../../common/partFinderCorporate.helpers";
import "../../../../common/search-error-message/ErrorMessage.vue";

export default Vue.component("driv-refine-vin-search", {
    data() {
        return {
            currentVin: null,
            autocompleteConfig: {
                minLength: 0,
                delay: 0,
                html: false,
                maxHeight: "300px",
                onlySelectValid: true,
                searchOnFocus: false,
            },
            errorMessage: false,
            errorMessageforMX: false,
            placeholder: this.getTranslation("17-character VIN Number"),
        };
    },
    props: {
        vin: {
            type: String,
            default: "",
        },
        subBrand: {
            type: String,
            default: "",
        },
        showLabels: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        getTranslation,
        handleSearch() {
            Vue.CatalogApi.CatalogApiService.getVinPartsList({
                vin: this.currentVin,
                brand: this.subBrand,
            })
                .then((resp) => {
                    const { year, make, model, ...filtersValues } = resp;
                    const ymmValues = mapVinOrLicensePlateResponse({ year, make, model });

                    if (this.resultsPagePath) {
                        const queryObj = {
                            ...ymmValues,
                            VIN_number: { value: this.currentVin },
                            type: { value: VIN_LP_VEHICLE_TYPE },
                            searchType: PART_FINDER_CORPORATE_SEARCH_TYPES.VEHICLE,
                        };

                        const url = createUrlToGoToSearchResults({
                            path: this.resultsPagePath,
                            queryObj,
                        });

                        const urlWithFilters = `${url}#${createQueryStringForFilters(
                            filtersValues,
                        )}`;

                        Vue.Global.Analytics.trackEvent(
                            "part-search",
                            "search-part-filter",
                            this.currentVin,
                            null,
                            {
                                callback: function () {
                                    window.location.assign(urlWithFilters);
                                },
                            },
                        );
                    }
                })
                .catch( error => {
                    switch (error.response.status) {
                    case 400:
                        this.errorMessage = true;
                        break;
                    case 409:
                        this.errorMessageforMX = true;
                        break;
                    default:
                        this.errorMessage = true;
                        break;
                    }
                });
        },
        handleVinKeydown(e) {
            this.errorMessage = false;
            if (!/^[a-zA-Z0-9_]+$/.test(e.key)) {
                this.errorMessage = true;
                console.error(`Invalid Character (${e.key}) entered`);
                e.preventDefault();
            }
        },
    },
    computed: {
        isDisabled() {
            return !this.currentVin;
        },
        resultsPagePath: () => {
            return window.location.pathname.replace(".html", "");
        },
    },
    created() {
        if (this.vin) {
            this.currentVin = this.vin;
        }
    },
});
</script>
